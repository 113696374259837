<template>
  <div id="app">
    <loading v-show="loading" :content="content"></loading>
    <router-view/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Loading from '@/components/loading'
export default {
  computed:{
    ...mapState([
      'loading',
      'content'
    ])
  },
  name: 'App',
  components: {
		Loading,
	}

}
</script>
