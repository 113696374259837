export default [
  {
    path: '/cashier',
    name: 'cashier',
    component: () => import('@/pages/cashier')
  },
  {
    path: '/link',
    name: 'link',
    component: () => import('@/pages/link')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/pages/pay')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('@/pages/paySuccess')
  },
  {
    path: '/scanPaySuccess',
    name: 'scanPaySuccess',
    component: () => import('@/pages/scanPaySuccess')
  }
]