import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import VConsole from 'vconsole';
import 'vant/lib/index.css'
import router from '@/router'
import store from '@/store'
import '@/assets/css/common.scss'
import 'element-ui/lib/theme-chalk/index.css'
// import WxSdk from '@/assets/js/weixin-sdk'

//调试工具
if (window.location.href.includes('-uat')) {
  new VConsole()
}


Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI)
// Vue.use(WxSdk)
Vue.use(VueClipboard)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
