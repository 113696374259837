import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    content:'加载中...'
  },
  mutations: {
    showLoading(state){
      state.loading = true    
    },
    hideLoading (state) {
      state.loading = false
    },
    setContent(state,val){
      state.content=val
    }
  }
})