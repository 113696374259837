
<template>
  <div class="loading">
    <van-loading class="loadingVant" vertical type="spinner" color="#fff">{{content}}</van-loading>
  </div>
</template>
 
<script>
  export default {
    name: 'loading',
    props:{
      content: {
        type: String,
        default: '加载中...'
      }
    },
    data () {
      return {}
    },
  }
</script>
<style scoped lang="scss">
  .loading{
    position: fixed;
    top:0;
    left:0;
    z-index:121;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    text-align: center;
    .loadingVant{
      width: 100px;
      padding:15px;
      border-radius: 10px;
      margin:0 auto;
      background: rgba(0,0,0,0.6);
    }
  }
</style>